import { graphql } from "gatsby"
import React from "react"
import { ActivityFiltersSection } from "src/components/ActivityFiltersSection"
import AssociationList from "src/components/AssociationList"
import AssociationSearchSection from "src/components/AssociationSearchSection"
import { ButtonUrl } from "src/components/Button"
import { Grid, HomeSection } from "src/components/Grid"
import Image from "src/components/Image"
import { MissionFiltersSection } from "src/components/MissionFiltersSection"
import { AssociationFilters, AssociationFilterType } from "src/constants/filters"
import { inferAssociationFilterType } from "src/helpers/inferAssociationFilterType"
import Layout from "src/layout"
import styled, { DefaultTheme } from "styled-components"

interface Data {
  assolib: {
    allAssociations: [Association]
    allDomainSearchIntentTagCounts: [DomainTagCount]
  }
}

interface PageContext extends AssociationFilters {
  highlight: DomainHighlight
  theme: DefaultTheme
}

const HighlightTemplate: GatsbyPage<Data, PageContext> = ({ data: { assolib }, pageContext }) => {
  const { highlight, ...filters } = pageContext
  const filterType = inferAssociationFilterType(filters)
  return (
    <Layout title={highlight.name} theme={pageContext.theme}>
      {filterType === AssociationFilterType.Association && <AssociationSearchSection {...filters} />}
      {filterType === AssociationFilterType.Activity && <ActivityFiltersSection {...filters} />}
      {filterType === AssociationFilterType.Mission && <MissionFiltersSection {...filters} />}
      <AboutSection highlight={highlight} />
      <AssociationList
        hasFilters
        associations={assolib.allAssociations}
        tagCounts={assolib.allDomainSearchIntentTagCounts}
        {...filters}
      />
    </Layout>
  )
}

const About = styled(Grid)`
  margin: 30px -10px 0;
  > div {
    flex: 1;
    margin: 10px;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const MobileCenter = styled.div`
  @media (max-width: 619px) {
    text-align: center;
  }
`
const AboutSection = ({ highlight }: { highlight: DomainHighlight }) => (
  <HomeSection>
    <About>
      <Image node={highlight.preview_image} src={highlight.preview_img_url} />
      <div>
        <h2>{highlight.name}</h2>
        <p>{highlight.description_text}</p>
        {highlight.button_link && (
          <MobileCenter>
            <ButtonUrl href={highlight.button_link} target="_blank" rel="noopener">
              {highlight.button_title || "Voir plus"}
            </ButtonUrl>
          </MobileCenter>
        )}
      </div>
    </About>
  </HomeSection>
)

export default HighlightTemplate

export const query = graphql`
  query(
    $type: Assolib_AssociationFilterType
    $associationIds: [ID!]
    $activityIds: [ID!]
    $missionIds: [ID!]
    $tagIds: [ID!]
    $ageIds: [ID!]
    $dayIds: [Assolib_DaysOfWeek!]
    $scheduleIds: [ID!]
    $domainId: ID!
  ) {
    assolib {
      allAssociations(
        type: $type
        ids: $associationIds
        activity_ids: $activityIds
        mission_ids: $missionIds
        tags: $tagIds
        age_sub_groups: $ageIds
        days: $dayIds
        schedule_groups: $scheduleIds
        domain: $domainId
      ) {
        ...SearchAssociationFields
      }
      allDomainSearchIntentTagCounts(
        age_sub_groups: $ageIds
        days: $dayIds
        schedule_groups: $scheduleIds
        domain: $domainId
      ) {
        tag {
          id
          name
        }
      }
    }
  }
`
